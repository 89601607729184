"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _LoginRedux = _interopRequireWildcard(require("../Redux/LoginRedux"));

// external libs
// redux
var IsLoggedInEpic = function IsLoggedInEpic(action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_LoginRedux.LoginTypes.ON_LOGIN_IS_LOGGED_IN), (0, _operators.switchMap)(function () {
    return (0, _rxjs.from)(_Api["default"].isLoggedIn()).pipe((0, _operators.switchMap)(function () {
      return (0, _rxjs.of)(_LoginRedux["default"].onLoginIsLoggedInResponse(true));
    }), (0, _operators.catchError)(function () {
      return (0, _rxjs.of)(_LoginRedux["default"].onLoginIsLoggedInResponse(false));
    }));
  }));
};

var _default = IsLoggedInEpic;
exports["default"] = _default;