"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.clearNotificationsR = exports.addInfo = exports.addSuccess = exports.addError = exports.addNotificationR = exports.INITIAL_STATE = exports.NOTIFICATION_TYPES = exports["default"] = exports.ErrorTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

var _createReducer;

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  addErrorNotification: ['message', 'title', 'translate'],
  addSuccessNotification: ['message', 'title', 'translate'],
  addInfoNotification: ['message', 'title', 'translate'],
  addNotification: ['type', 'message', 'title', 'translate'],
  clearNotifications: null
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var ErrorTypes = Types;
exports.ErrorTypes = ErrorTypes;
var _default = Creators;
exports["default"] = _default;
var NOTIFICATION_TYPES = {
  success: 'success',
  error: 'error',
  info: 'info'
};
exports.NOTIFICATION_TYPES = NOTIFICATION_TYPES;

/* ------------- Initial State ------------- */
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  // eslint-disable-line
  notificationToShow: []
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var addNotificationR = function addNotificationR(state, _ref) {
  var type = _ref.type,
      message = _ref.message,
      _ref$title = _ref.title,
      title = _ref$title === void 0 ? '' : _ref$title,
      _ref$translate = _ref.translate,
      translate = _ref$translate === void 0 ? false : _ref$translate;
  return state.merge({
    notificationToShow: [].concat((0, _toConsumableArray2["default"])(state.notificationToShow), [{
      type: type,
      message: message,
      title: title,
      translate: translate
    }])
  });
};

exports.addNotificationR = addNotificationR;

var addError = function addError(state, _ref2) {
  var message = _ref2.message,
      title = _ref2.title,
      translate = _ref2.translate;
  return addNotificationR(state, {
    type: NOTIFICATION_TYPES.error,
    message: message,
    title: title,
    translate: translate
  });
};

exports.addError = addError;

var addSuccess = function addSuccess(state, _ref3) {
  var message = _ref3.message,
      title = _ref3.title,
      translate = _ref3.translate;
  return addNotificationR(state, {
    type: NOTIFICATION_TYPES.success,
    message: message,
    title: title,
    translate: translate
  });
};

exports.addSuccess = addSuccess;

var addInfo = function addInfo(state, _ref4) {
  var message = _ref4.message,
      title = _ref4.title,
      translate = _ref4.translate;
  return addNotificationR(state, {
    type: NOTIFICATION_TYPES.success,
    message: message,
    title: title,
    translate: translate
  });
};

exports.addInfo = addInfo;

var clearNotificationsR = function clearNotificationsR(state) {
  return state.merge({
    notificationToShow: []
  });
};
/* ------------- Hookup Reducers To Types ------------- */


exports.clearNotificationsR = clearNotificationsR;
var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (_createReducer = {}, (0, _defineProperty2["default"])(_createReducer, Types.ADD_ERROR_NOTIFICATION, addError), (0, _defineProperty2["default"])(_createReducer, Types.ADD_SUCCESS_NOTIFICATION, addSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ADD_INFO_NOTIFICATION, addInfo), (0, _defineProperty2["default"])(_createReducer, Types.ADD_NOTIFICATION, addNotificationR), (0, _defineProperty2["default"])(_createReducer, Types.CLEAR_NOTIFICATIONS, clearNotificationsR), _createReducer));
exports.reducer = reducer;