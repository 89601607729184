"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _colors = require("@material-ui/core/colors");

var _colorManipulator = require("@material-ui/core/styles/colorManipulator");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var palette = {
  primary: {
    main: _colors.teal[500]
  },
  secondary: {
    main: _colors.blueGrey[700]
  },
  primary1Color: _colors.teal[500],
  primary2Color: _colors.blueGrey[700],
  primary3Color: _colors.teal[500],
  accent1Color: _colors.blueGrey[500],
  accent2Color: _colors.grey[50],
  accent3Color: _colors.grey[500],
  textColor: '#000',
  alternateTextColor: '#FFFFFF',
  canvasColor: '#FFFFFF',
  borderColor: _colors.grey[300],
  disabledColor: (0, _colorManipulator.fade)('#000', 0.3),
  pickerHeaderColor: '#009688',
  clockCircleColor: (0, _colorManipulator.fade)('#000', 0.07),
  shadowColor: '#000',
  type: 'light'
};
var _default = {
  fontFamily: 'Open Sans, sans-serif',
  typography: {
    useNextVariants: true
  },
  palette: _objectSpread({}, palette),
  checkbox: {
    boxColor: palette.accent3Color
  },
  table: {
    backgroundColor: palette.canvasColor
  },
  tableFooter: {
    borderColor: palette.borderColor,
    textColor: palette.accent3Color
  },
  tableHeader: {
    borderColor: palette.borderColor
  },
  tableHeaderColumn: {
    textColor: palette.accent3Color,
    height: 56,
    spacing: 24
  },
  tableRow: {
    hoverColor: palette.accent2Color,
    stripeColor: _colors.grey[100],
    selectedColor: (0, _colorManipulator.lighten)(palette.borderColor, 0.5),
    textColor: palette.textColor,
    borderColor: palette.borderColor,
    height: 48
  },
  tableRowColumn: {
    height: 48,
    spacing: 24
  },
  raisedButton: {
    secondaryColor: palette.accent1Color
  },
  tabs: {
    backgroundColor: '#eef1f5',
    textColor: _colors.grey[500],
    selectedTextColor: palette.textColor
  },
  inkBar: {
    backgroundColor: palette.primary1Color
  }
};
exports["default"] = _default;