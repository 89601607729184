"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _Fab = _interopRequireDefault(require("@material-ui/core/Fab"));

var ActionButton = function ActionButton(props) {
  return _react["default"].createElement(_Fab["default"], {
    classes: props.classes,
    color: props.color,
    disabled: props.disabled,
    onClick: props.onClick,
    size: props.size
  }, props.children);
};

var _default = ActionButton;
exports["default"] = _default;