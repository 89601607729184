"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.setWidgetsR = exports.INITIAL_STATE = exports["default"] = exports.WidgetTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

// external libs

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  setWidgets: ['widgets']
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var WidgetTypes = Types;
exports.WidgetTypes = WidgetTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  // eslint-disable-line
  widgets: []
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var setWidgetsR = function setWidgetsR(state, _ref) {
  var widgets = _ref.widgets;
  return state.set('widgets', widgets);
};
/* ------------- Hookup Reducers To Types ------------- */


exports.setWidgetsR = setWidgetsR;
var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (0, _defineProperty2["default"])({}, Types.SET_WIDGETS, setWidgetsR));
exports.reducer = reducer;