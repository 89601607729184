"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var ImageComponent = function ImageComponent(props) {
  return props.value ? _react["default"].createElement("img", {
    alt: props.value,
    className: "img-thumbnail img-table",
    src: "/images/".concat(props.value)
  }) : _react["default"].createElement("div", null);
};

var _default = ImageComponent;
exports["default"] = _default;