"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.toggleDrawer = exports.INITIAL_STATE = exports["default"] = exports.DrawerTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

// external libs

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  handleToggle: null
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var DrawerTypes = Types;
exports.DrawerTypes = DrawerTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  // eslint-disable-line
  open: false
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var toggleDrawer = function toggleDrawer(state) {
  return state.merge({
    open: !state.open
  });
};
/* ------------- Hookup Reducers To Types ------------- */


exports.toggleDrawer = toggleDrawer;
var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (0, _defineProperty2["default"])({}, Types.HANDLE_TOGGLE, toggleDrawer));
exports.reducer = reducer;