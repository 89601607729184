"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _ListRedux = _interopRequireWildcard(require("../Redux/ListRedux"));

var _HelperRedux = _interopRequireWildcard(require("../Redux/HelperRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

// external libs
// redux
var ListSettingsEpic = [function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_ListRedux.ListTypes.ON_LOAD_LIST_DATA), (0, _operators.switchMap)(function (action) {
    return (0, _rxjs.from)(_Api["default"].listData(action.module, action.page, action.limit, action.orderColumn, action.orderDirection, action.filter)).pipe((0, _operators.switchMap)(function (response) {
      return (0, _rxjs.of)(_ListRedux["default"].onLoadListDataSuccess(response.data));
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _ListRedux["default"].onListStopFetching()]);
    }));
  }));
}, function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_ListRedux.ListTypes.ON_LOAD_LIST_WIDGET_DATA), (0, _operators.switchMap)(function (action) {
    return (0, _rxjs.from)(_Api["default"].listWidgetData(action.module, action.widgetName, action.page, action.limit, action.orderColumn, action.orderDirection, action.filter, action.parentId, action.parentModule)).pipe((0, _operators.switchMap)(function (response) {
      return (0, _rxjs.of)(_ListRedux["default"].onLoadListWidgetDataSuccess(action.module, action.widgetName, response.data));
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _ListRedux["default"].onWidgetStopFetching()]);
    }));
  }));
}, function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_HelperRedux.HelperTypes.GET_RESOURCES_REQUEST), (0, _operators.switchMap)(function (action) {
    return (0, _rxjs.from)(_Api["default"].getResources(action.resourceName)).pipe((0, _operators.switchMap)(function (response) {
      return (0, _rxjs.of)(_HelperRedux["default"].getResourcesRequestSuccess(action.resourceName, response.data));
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG')]);
    }));
  }));
}];
var _default = ListSettingsEpic;
exports["default"] = _default;