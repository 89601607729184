"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var initValue = function initValue(formItem) {
  if (typeof formItem.defaultValue !== 'undefined') {
    return formItem.defaultValue;
  }

  if (formItem.type === 'bool') {
    return false;
  }

  return '';
};

var initEmptyData = function initEmptyData(config) {
  return Object.keys(config).reduce(function (acc, tab) {
    acc = config[tab].reduce(function (accS, formItem) {
      if (formItem.translated) {
        if (!accS.texts) {
          accS.texts = {};
        }

        accS.texts[formItem.name] = initValue(formItem);
      } else {
        accS[formItem.name] = initValue(formItem);
      }

      return accS;
    }, acc);
    return acc;
  }, {});
};

var _default = initEmptyData;
exports["default"] = _default;