"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var LinkToParentComponent = function LinkToParentComponent(props) {
  if (!props.value) {
    return null;
  }

  var handleClick = function handleClick(e) {
    e.preventDefault();

    if (!props.parentModule) {
      var _Object$keys$reduce = Object.keys(props.item).reduce(function (acc, columnKey) {
        if ((0, _typeof2["default"])(props.item[columnKey]) === 'object') {
          return [columnKey, props.item[columnKey].id];
        }

        return acc;
      }, ['', 0]),
          _Object$keys$reduce2 = (0, _slicedToArray2["default"])(_Object$keys$reduce, 2),
          key = _Object$keys$reduce2[0],
          _id = _Object$keys$reduce2[1];

      props.requestRedirectTo('view', key, _id);
    } else if ((0, _typeof2["default"])(props.item[props.parentModule]) === 'object' || (0, _typeof2["default"])(props.item[props.parentDataObject]) === 'object') {
      var idKey = 'id';
      var dataKey = props.parentModule;

      if (props.idKey) {
        idKey = props.idKey;
      }

      if (props.parentDataObject) {
        dataKey = props.parentDataObject;
      }

      props.requestRedirectTo('view', props.parentModule, props.item[dataKey][idKey]);
    }
  };

  return _react["default"].createElement("span", null, _react["default"].createElement("a", {
    href: "#",
    onClick: handleClick
  }, props.value));
};

LinkToParentComponent.defaultProps = {
  idKey: '',
  parentDataObject: '',
  parentModule: '',
  requestRedirectTo: function requestRedirectTo() {}
};
var _default = LinkToParentComponent;
exports["default"] = _default;