"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _i18nReact = _interopRequireDefault(require("i18n-react"));

var TranslationComponent = function TranslationComponent(props) {
  if (!props.value) {
    return null;
  }

  var columnArray = props.column.split('.');
  return _react["default"].createElement("span", null, _i18nReact["default"].translate("select.".concat(columnArray[columnArray.length - 1], ".").concat(props.value)));
};

var _default = TranslationComponent;
exports["default"] = _default;