"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _ActionPermissionFormField = _interopRequireDefault(require("./ActionPermissionFormField"));

var _Address = _interopRequireDefault(require("./Address"));

var _AssociatedSelect = _interopRequireDefault(require("./AssociatedSelect"));

var _Bool = _interopRequireDefault(require("./Bool"));

var _Code = _interopRequireDefault(require("./Code"));

var _DateInputsFormField = _interopRequireDefault(require("./DateInputsFormField"));

var _Datetime = _interopRequireDefault(require("./Datetime"));

var _GalleryFormItem = _interopRequireDefault(require("./GalleryFormItem"));

var _KeyValueJson = _interopRequireDefault(require("./KeyValueJson"));

var _Number = _interopRequireDefault(require("./Number"));

var _Position = _interopRequireDefault(require("./Position"));

var _RichText = _interopRequireDefault(require("./RichText"));

var _Select = _interopRequireDefault(require("./Select"));

var _SingleFileUpload = _interopRequireDefault(require("./SingleFileUpload"));

var _StackTrace = _interopRequireDefault(require("./StackTrace"));

var _Tags = _interopRequireDefault(require("./Tags"));

var _Text = _interopRequireDefault(require("./Text"));

var _TextArea = _interopRequireDefault(require("./TextArea"));

var _JsonTableField = _interopRequireDefault(require("./JsonTableField"));

var _VideoUrlField = _interopRequireDefault(require("./VideoUrlField"));

var _default = {
  actionPermission: _ActionPermissionFormField["default"],
  address: _Address["default"],
  associatedSelect: _AssociatedSelect["default"],
  bool: _Bool["default"],
  code: _Code["default"],
  dateInputs: _DateInputsFormField["default"],
  datetime: _Datetime["default"],
  file: _SingleFileUpload["default"],
  gallery: _GalleryFormItem["default"],
  image: _SingleFileUpload["default"],
  keyValueJson: _KeyValueJson["default"],
  number: _Number["default"],
  position: _Position["default"],
  richText: _RichText["default"],
  select: _Select["default"],
  stackTrace: _StackTrace["default"],
  tags: _Tags["default"],
  text: _Text["default"],
  textArea: _TextArea["default"],
  jsonTable: _JsonTableField["default"],
  videoUrl: _VideoUrlField["default"]
};
exports["default"] = _default;