"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Routes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("@babel/runtime/helpers/interopRequireWildcard"));

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

var _reactLoadable = _interopRequireDefault(require("react-loadable"));

var _Loader = _interopRequireDefault(require("../Components/_active/Loader"));

var _ListContainer = _interopRequireDefault(require("../Containers/ListContainer"));

var _EditContainer = _interopRequireDefault(require("../Containers/EditContainer"));

// We only need to import the modules necessary for initial render

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */
var HomeContainer = (0, _reactLoadable["default"])({
  loader: function loader() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2["default"])(require('../Containers/HomeContainer'));
    });
  },
  loading: _Loader["default"]
});
var ErrorContainer = (0, _reactLoadable["default"])({
  loader: function loader() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2["default"])(require('../Containers/ErrorContainer'));
    });
  },
  loading: _Loader["default"]
});
var StartupContainer = (0, _reactLoadable["default"])({
  loader: function loader() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2["default"])(require('../Containers/StartupContainer'));
    });
  },
  loading: _Loader["default"]
});
var LoginContainer = (0, _reactLoadable["default"])({
  loader: function loader() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2["default"])(require('../Containers/LoginContainer'));
    });
  },
  loading: _Loader["default"]
});

var Routes = function Routes() {
  return _react["default"].createElement(_reactRouterDom.Switch, null, _react["default"].createElement(_reactRouterDom.Route, {
    component: ErrorContainer,
    key: "ErrorContainer",
    path: "/404"
  }), _react["default"].createElement(_reactRouterDom.Route, {
    component: HomeContainer,
    key: "HomeContainer",
    path: "/dashboard"
  }), _react["default"].createElement(_reactRouterDom.Route, {
    component: LoginContainer,
    key: "LoginContainer",
    path: "/login"
  }), _react["default"].createElement(_reactRouterDom.Route, {
    component: _EditContainer["default"],
    key: "AddContainer",
    path: "/:module/add"
  }), _react["default"].createElement(_reactRouterDom.Route, {
    component: _EditContainer["default"],
    key: "EditContainer",
    path: "/:module/edit/:id"
  }), _react["default"].createElement(_reactRouterDom.Route, {
    component: _EditContainer["default"],
    key: "ViewContainer",
    path: "/:module/view/:id"
  }), _react["default"].createElement(_reactRouterDom.Route, {
    component: _ListContainer["default"],
    key: "ListContainer",
    path: "/:module"
  }), _react["default"].createElement(_reactRouterDom.Route, {
    component: StartupContainer,
    key: "StartupContainer",
    path: "/"
  }));
};

exports.Routes = Routes;
var _default = Routes;
exports["default"] = _default;