"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Utilities = require("../../src/Lib/Utilities");

var canBeEmpty = function canBeEmpty(value) {
  return typeof value === 'undefined' || value === null || value.length === 0;
};
/**
 * @param {string} value
 * @param {RegExp} regExpTest
 */


var regExp = function regExp(value, regExpTest) {
  return new RegExp(regExpTest).test(value) ? true : (0, _Utilities.createValidationError)('validationErrors.badRegExp', {
    pattern: regExpTest
  });
};
/**
 * @param {string} value
 * @param {int} min
 */


var minLength = function minLength(value, min) {
  return typeof value !== 'undefined' && min > 0 && value.length >= min ? true : (0, _Utilities.createValidationError)('validationErrors.tooShort', {
    length: min
  });
};
/**
 * @param {string} value
 * @param {int} max
 */


var maxLength = function maxLength(value, max) {
  return typeof value !== 'undefined' && max > 0 && value.length <= max ? true : (0, _Utilities.createValidationError)('validationErrors.tooLong', {
    length: max
  });
};
/**
 * @param {string} value
 */


var isPhone = function isPhone(value) {
  return typeof value !== 'undefined' && (0, _Utilities.validatePhone)(value) ? true : (0, _Utilities.createValidationError)('validationErrors.badPhoneFormat');
};
/**
 * @param {string} value
 */


var isEmail = function isEmail(value) {
  return typeof value !== 'undefined' && (0, _Utilities.validateEmail)(value) ? true : (0, _Utilities.createValidationError)('validationErrors.email');
};
/**
 * @param {string} value
 */


var isNumber = function isNumber(value) {
  return (0, _Utilities.isNumeric)(value) ? true : (0, _Utilities.createValidationError)('validationErrors.numeric');
};
/**
 * @param {string} value
 * @param {object} range
 */


var inRange = function inRange(value, range) {
  return isNumber(value) && range.start !== null && range.end !== null && !(range.start > parseInt(value, 10) || range.end < parseInt(value, 10)) ? true : (0, _Utilities.createValidationError)('validationErrors.range', {
    start: range.start,
    end: range.end
  });
};
/**
 * @param {string} value
 */


var isInteger = function isInteger(value) {
  return /^\d+$/.test(value) ? true : (0, _Utilities.createValidationError)('validationErrors.integer');
};
/**
 * @param {string} value
 */


var isRequired = function isRequired(value) {
  return typeof value !== 'undefined' && "".concat(value).length > 0 ? true : (0, _Utilities.createValidationError)('validationErrors.required');
};

var _default = {
  canBeEmpty: canBeEmpty,
  regExp: regExp,
  minLength: minLength,
  maxLength: maxLength,
  isPhone: isPhone,
  isEmail: isEmail,
  isNumber: isNumber,
  inRange: inRange,
  isInteger: isInteger,
  isRequired: isRequired
};
exports["default"] = _default;