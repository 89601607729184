"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.changeEditChanged = exports.changeViewChanged = exports.updateSuccess = exports.updateRequest = exports.saveSuccess = exports.saveRequest = exports.changeValidationError = exports.changeValidationErrors = exports.validationRequest = exports.loadFormDataSuccess = exports.loadFormData = exports.changeData = exports.setFetching = exports.loadConfigSuccess = exports.loadConfig = exports.loadView = exports.loadEdit = exports.loadAdd = exports.INITIAL_STATE = exports["default"] = exports.EditTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

var _BaseAddEdit = _interopRequireDefault(require("../__Core/BaseAddEdit"));

var _DataObjectInit = _interopRequireDefault(require("../__Core/DataObjectInit"));

var _createReducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  onLoadAdd: ['add'],
  onLoadEdit: ['edit'],
  onLoadView: ['view'],
  onEditLoadFormConfig: ['data'],
  onEditLoadFormConfigSuccess: ['name', 'config'],
  onEditSetFetching: ['fetching'],
  onEditChangeData: ['attr', 'value'],
  onEditLoadFormData: ['data'],
  onEditLoadFormDataSuccess: ['name', 'data'],
  onEditValidationRequest: ['data'],
  onEditChangeValidationErrors: ['validationErrors'],
  onEditChangeValidationError: ['key', 'data'],
  onEditSaveRequest: ['data'],
  onEditSaveSuccess: ['data'],
  onUpdateColumnRequest: ['data'],
  onUpdateColumnSuccess: ['data'],
  onEditChangeViewChanged: ['viewChanged'],
  onEditChangeEditChanged: ['editChanged']
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var EditTypes = Types;
exports.EditTypes = EditTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  // eslint-disable-line
  fetching: false,
  fetchingConfig: false,
  adds: {},
  edits: {},
  views: {},
  activeAddEdit: new _BaseAddEdit["default"](),
  data: {},
  dataChanged: false,
  validationErrors: {},
  viewChanged: false,
  editChanged: false
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var loadAdd = function loadAdd(state, _ref) {
  var add = _ref.add;

  var adds = _objectSpread({}, state.adds);

  if (!adds[add.name]) {
    adds[add.name] = new _BaseAddEdit["default"](_objectSpread({}, add, {
      type: 'ADD'
    }));
  }

  return state.merge({
    adds: adds,
    activeAddEdit: adds[add.name],
    dataChanged: false,
    data: adds[add.name].getData()
  });
};

exports.loadAdd = loadAdd;

var loadEdit = function loadEdit(state, _ref2) {
  var edit = _ref2.edit;

  var edits = _objectSpread({}, state.edits);

  if (!edits[edit.name]) {
    edits[edit.name] = new _BaseAddEdit["default"](_objectSpread({}, edit, {
      type: 'EDIT'
    }));
  }

  return state.merge({
    edits: edits,
    activeAddEdit: edits[edit.name],
    dataChanged: false,
    data: edits[edit.name].getData(),
    editChanged: true
  });
};

exports.loadEdit = loadEdit;

var loadView = function loadView(state, _ref3) {
  var view = _ref3.view;

  var views = _objectSpread({}, state.views);

  if (!views[view.name]) {
    views[view.name] = new _BaseAddEdit["default"](_objectSpread({}, view, {
      type: 'VIEW'
    }));
  }

  return state.merge({
    views: views,
    activeAddEdit: views[view.name],
    dataChanged: false,
    data: views[view.name].getData(),
    viewChanged: true
  });
};

exports.loadView = loadView;

var loadConfig = function loadConfig(state) {
  return state.merge({
    fetchingConfig: true
  });
};

exports.loadConfig = loadConfig;

var loadConfigSuccess = function loadConfigSuccess(state, _ref4) {
  var name = _ref4.name,
      config = _ref4.config;

  if (state.activeAddEdit.getName() === name) {
    var data = state.data;

    if (state.activeAddEdit.getType() === 'ADD') {
      data = (0, _DataObjectInit["default"])(config);
    } else if (state.activeAddEdit.getType() === 'EDIT') {
      return state.merge({
        activeAddEdit: state.activeAddEdit.setConfig(config),
        fetchingConfig: false
      });
    }

    return state.merge({
      activeAddEdit: state.activeAddEdit.setConfig(config).setData(data),
      data: data,
      fetchingConfig: false
    });
  }

  return state.merge({
    fetchingConfig: false
  });
};

exports.loadConfigSuccess = loadConfigSuccess;

var setFetching = function setFetching(state, _ref5) {
  var fetching = _ref5.fetching;
  return state.merge({
    fetching: fetching
  });
};

exports.setFetching = setFetching;

var changeData = function changeData(state, _ref6) {
  var attr = _ref6.attr,
      value = _ref6.value;
  return state.setIn(['data', attr], value).merge({
    dataChanged: true
  });
};

exports.changeData = changeData;

var loadFormData = function loadFormData(state) {
  return state.merge({
    fetching: true,
    data: {}
  });
};

exports.loadFormData = loadFormData;

var loadFormDataSuccess = function loadFormDataSuccess(state, _ref7) {
  var name = _ref7.name,
      data = _ref7.data;

  if (state.activeAddEdit.getName() === name) {
    var dataWitTexts = data.texts && Array.isArray(data.texts) ? _objectSpread({}, data, {
      texts: data.texts[0]
    }) : data;
    var dataObject = state.dataChanged ? state.data.asMutable() : dataWitTexts;
    var activeAddEdit = null;

    if (state.activeAddEdit.getType() === 'VIEW') {
      activeAddEdit = state.views[state.activeAddEdit.getName()].asMutable();
    } else if (state.activeAddEdit.getType() === 'EDIT') {
      activeAddEdit = state.edits[state.activeAddEdit.getName()].asMutable();
    }

    activeAddEdit = activeAddEdit.setData(dataObject);
    return state.merge({
      fetching: false,
      activeAddEdit: activeAddEdit,
      data: dataObject
    });
  }

  return state.merge({
    fetching: false
  });
};

exports.loadFormDataSuccess = loadFormDataSuccess;

var validationRequest = function validationRequest(state) {
  return state.merge({
    fetching: true
  });
};

exports.validationRequest = validationRequest;

var changeValidationErrors = function changeValidationErrors(state, _ref8) {
  var validationErrors = _ref8.validationErrors;
  return state.merge({
    validationErrors: validationErrors
  });
};

exports.changeValidationErrors = changeValidationErrors;

var changeValidationError = function changeValidationError(state, _ref9) {
  var key = _ref9.key,
      data = _ref9.data;

  var validationErrors = _objectSpread({}, state.validationErrors);

  validationErrors[key] = data;
  return state.merge({
    validationErrors: validationErrors
  });
};

exports.changeValidationError = changeValidationError;

var saveRequest = function saveRequest(state) {
  return state.merge({
    fetching: true
  });
};

exports.saveRequest = saveRequest;

var saveSuccess = function saveSuccess(state, _ref10) {
  var data = _ref10.data;
  return state.merge({
    fetching: false,
    viewChanged: true,
    editChanged: true
  });
};

exports.saveSuccess = saveSuccess;

var updateRequest = function updateRequest(state) {
  return state.merge({
    fetching: true
  });
};

exports.updateRequest = updateRequest;

var updateSuccess = function updateSuccess(state, _ref11) {
  var data = _ref11.data;
  return state.merge({
    fetching: false,
    viewChanged: true,
    editChanged: true
  });
};

exports.updateSuccess = updateSuccess;

var changeViewChanged = function changeViewChanged(state, _ref12) {
  var viewChanged = _ref12.viewChanged;
  return state.merge({
    viewChanged: viewChanged
  });
};

exports.changeViewChanged = changeViewChanged;

var changeEditChanged = function changeEditChanged(state, _ref13) {
  var editChanged = _ref13.editChanged;
  return state.merge({
    editChanged: editChanged
  });
};
/* ------------- Hookup Reducers To Types ------------- */


exports.changeEditChanged = changeEditChanged;
var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (_createReducer = {}, (0, _defineProperty2["default"])(_createReducer, Types.ON_LOAD_ADD, loadAdd), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOAD_EDIT, loadEdit), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOAD_VIEW, loadView), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_LOAD_FORM_CONFIG, loadConfig), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_LOAD_FORM_CONFIG_SUCCESS, loadConfigSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_SET_FETCHING, setFetching), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_CHANGE_DATA, changeData), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_LOAD_FORM_DATA, loadFormData), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_LOAD_FORM_DATA_SUCCESS, loadFormDataSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_VALIDATION_REQUEST, validationRequest), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_CHANGE_VALIDATION_ERRORS, changeValidationErrors), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_CHANGE_VALIDATION_ERROR, changeValidationError), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_SAVE_REQUEST, saveRequest), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_SAVE_SUCCESS, saveSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_UPDATE_COLUMN_REQUEST, updateRequest), (0, _defineProperty2["default"])(_createReducer, Types.ON_UPDATE_COLUMN_SUCCESS, updateSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_CHANGE_VIEW_CHANGED, changeViewChanged), (0, _defineProperty2["default"])(_createReducer, Types.ON_EDIT_CHANGE_EDIT_CHANGED, changeEditChanged), _createReducer));
exports.reducer = reducer;