"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _i18nReact = _interopRequireDefault(require("i18n-react"));

var _Api = _interopRequireDefault(require("../Services/Api"));

var _Utilities = require("../Lib/Utilities");

var _LoginRedux = _interopRequireWildcard(require("../Redux/LoginRedux"));

var _UserRedux = _interopRequireDefault(require("../Redux/UserRedux"));

var _NavigationRedux = _interopRequireDefault(require("../Redux/NavigationRedux"));

// external libs
// redux
var LoginEpic = function LoginEpic(action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_LoginRedux.LoginTypes.ON_LOGIN), (0, _operators.switchMap)(function (action) {
    return (0, _rxjs.from)(_Api["default"].login(action.data)).pipe((0, _operators.switchMap)(function (response) {
      var urlParams = new URLSearchParams(window.location.search);
      var redirect = urlParams.get('redirectTo');
      return (0, _rxjs.from)([_UserRedux["default"].onLoadUser(response.data.user, response.data.presentations), _LoginRedux["default"].onLoginSuccess(), _NavigationRedux["default"].onLoadNavigation(response.data.navigation), _NavigationRedux["default"].onRequestRedirectTo("/dashboard".concat(redirect ? "?redirectTo=".concat(redirect) : ''))]);
    }), (0, _operators.catchError)(function (error) {
      var errorObject = {};
      var errorCode = '';

      if (error.response) {
        errorCode = error.response.data;

        if (error.response.data === 'BAD_EMAIL') {
          errorObject.email = [{
            result: (0, _Utilities.createValidationError)(_i18nReact["default"].translate('errorCodes.BAD_EMAIL'))
          }];
        } else if (error.response.data === 'BAD_PASSWORD') {
          errorObject.password = [{
            result: (0, _Utilities.createValidationError)(_i18nReact["default"].translate('errorCodes.BAD_PASSWORD'))
          }];
        }
      } else {
        errorCode = 'SOMETHING_WRONG';
      }

      return (0, _rxjs.of)(_LoginRedux["default"].onLoginFailure(errorCode, errorObject));
    }));
  }));
};

var _default = LoginEpic;
exports["default"] = _default;