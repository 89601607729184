"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _YesNoComponent = _interopRequireDefault(require("../_passive/YesNoComponent"));

var _ActualWeatherComponent = _interopRequireDefault(require("./ActualWeatherComponent"));

var _ShowDateTimeComponent = _interopRequireDefault(require("../_passive/ShowDateTimeComponent"));

var _EnumErrorShowLabel = _interopRequireDefault(require("../_passive/EnumErrorShowLabel"));

var _EnumChangeValue = _interopRequireDefault(require("../_passive/EnumChangeValue"));

var _TranslationComponent = _interopRequireDefault(require("../_passive/TranslationComponent"));

var _LinkToParentComponent = _interopRequireDefault(require("../_passive/LinkToParentComponent"));

var _ImageComponent = _interopRequireDefault(require("../_passive/ImageComponent"));

var _default = {
  ActualWeatherComponent: _ActualWeatherComponent["default"],
  YesNoComponent: _YesNoComponent["default"],
  ShowDateTimeComponent: _ShowDateTimeComponent["default"],
  EnumErrorShowLabel: _EnumErrorShowLabel["default"],
  EnumChangeValue: _EnumChangeValue["default"],
  TranslationComponent: _TranslationComponent["default"],
  LinkToParentComponent: _LinkToParentComponent["default"],
  ImageComponent: _ImageComponent["default"]
};
exports["default"] = _default;