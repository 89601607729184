"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _EditRedux = _interopRequireWildcard(require("../Redux/EditRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

var _NavigationRedux = _interopRequireDefault(require("../Redux/NavigationRedux"));

var _ListRedux = _interopRequireDefault(require("../Redux/ListRedux"));

// external libs
// redux
var GetConfigEpic = [function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_EditRedux.EditTypes.ON_EDIT_VALIDATION_REQUEST), (0, _operators.switchMap)(function (action) {
    var url = action.data.type === 'ADD' ? _Api["default"].addValidateData(action.data.module, action.data.data) : _Api["default"].editValidateData(action.data.module, action.data.data);
    return (0, _rxjs.from)(url).pipe((0, _operators.switchMap)(function (response) {
      if (response.status === 200 && response.data.type === 'VALIDATION_RESULT') {
        return (0, _rxjs.of)(_EditRedux["default"].onEditChangeValidationErrors(response.data.errors));
      }

      throw response;
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _EditRedux["default"].onEditSetFetching(false)]);
    }));
  }));
}, function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_EditRedux.EditTypes.ON_EDIT_SAVE_REQUEST), (0, _operators.switchMap)(function (action) {
    var apiFunction = action.data.type === 'ADD' ? _Api["default"].addData : _Api["default"].editData;
    return (0, _rxjs.from)(apiFunction(action.data.module, action.data.data)).pipe((0, _operators.switchMap)(function (response) {
      if (response.status === 200 && response.data.type === 'VALIDATION_RESULT') {
        return (0, _rxjs.from)([_EditRedux["default"].onEditChangeValidationErrors(response.data.errors)]);
      }

      if (response.status === 200) {
        return (0, _rxjs.from)([_NavigationRedux["default"].onRequestRedirectTo("/".concat(action.data.module)), _EditRedux["default"].onLoadEdit({
          name: action.data.module
        }), _EditRedux["default"].onEditSaveSuccess(response.data), _NotificationRedux["default"].addSuccessNotification('SUCCESSFULLY_SAVED_MESSAGE', 'SUCCESSFULLY_SAVED_TITLE', true)]);
      }

      throw response;
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _EditRedux["default"].onEditSetFetching(false)]);
    }));
  }));
}, function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_EditRedux.EditTypes.ON_UPDATE_COLUMN_REQUEST), (0, _operators.switchMap)(function (action) {
    return (0, _rxjs.from)(_Api["default"].updateData(action.data.module, action.data.data)).pipe((0, _operators.switchMap)(function (response) {
      if (response.status === 200) {
        return (0, _rxjs.from)([_EditRedux["default"].onUpdateColumnSuccess(response.data), _ListRedux["default"].onLoadListData(action.data.listDataOptions.name, action.data.listDataOptions.page, action.data.listDataOptions.limit, action.data.listDataOptions.orderColumn, action.data.listDataOptions.orderDirection, action.data.listDataOptions.filter), _NotificationRedux["default"].addSuccessNotification('SUCCESSFULLY_SAVED_MESSAGE', 'SUCCESSFULLY_SAVED_TITLE', true)]);
      }

      throw response;
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _EditRedux["default"].onEditSetFetching(false)]);
    }));
  }));
}];
var _default = GetConfigEpic;
exports["default"] = _default;