"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.stopFetching = exports.onSingleFileUploadSuccess = exports.onSingleFileUpload = exports.onInitData = exports.INITIAL_STATE = exports["default"] = exports.HelperTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

var _createReducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  onHelperInitData: ['name', 'initData'],
  onHelperSingleFileUpload: ['name', 'data', 'fileType'],
  onHelperSingleFileUploadSuccess: ['name', 'filename'],
  onHelperStopFetching: ['name'],
  getResourcesRequest: ['resourceName'],
  getResourcesRequestSuccess: ['resourceName', 'data']
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var HelperTypes = Types;
exports.HelperTypes = HelperTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  data: {},
  fetching: {},
  resources: {}
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var onInitData = function onInitData(state, _ref) {
  var name = _ref.name,
      initData = _ref.initData;

  var data = _objectSpread({}, state.data);

  var fetching = _objectSpread({}, state.fetching);

  data[name] = initData;
  fetching[name] = false;
  return state.merge({
    data: data
  });
};

exports.onInitData = onInitData;

var onSingleFileUpload = function onSingleFileUpload(state, _ref2) {
  var name = _ref2.name;

  var fetching = _objectSpread({}, state.fetching);

  fetching[name] = true;
  return state.merge({
    fetching: fetching
  });
};

exports.onSingleFileUpload = onSingleFileUpload;

var onSingleFileUploadSuccess = function onSingleFileUploadSuccess(state, _ref3) {
  var name = _ref3.name,
      filename = _ref3.filename;

  var data = _objectSpread({}, state.data);

  var fetching = _objectSpread({}, state.fetching);

  data[name] = _objectSpread({}, data[name], {
    filename: filename
  });
  fetching[name] = false;
  return state.merge({
    data: data,
    fetching: fetching
  });
};

exports.onSingleFileUploadSuccess = onSingleFileUploadSuccess;

var stopFetching = function stopFetching(state, _ref4) {
  var name = _ref4.name;

  var fetching = _objectSpread({}, state.fetching);

  fetching[name] = false;
  return state.merge({
    fetching: fetching
  });
};

exports.stopFetching = stopFetching;

var getResourcesRequestSuccessR = function getResourcesRequestSuccessR(state, _ref5) {
  var resourceName = _ref5.resourceName,
      data = _ref5.data;
  return state.setIn(['resources', resourceName], data);
};
/* ------------- Hookup Reducers To Types ------------- */


var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (_createReducer = {}, (0, _defineProperty2["default"])(_createReducer, Types.ON_HELPER_INIT_DATA, onInitData), (0, _defineProperty2["default"])(_createReducer, Types.ON_HELPER_SINGLE_FILE_UPLOAD, onSingleFileUpload), (0, _defineProperty2["default"])(_createReducer, Types.ON_HELPER_SINGLE_FILE_UPLOAD_SUCCESS, onSingleFileUploadSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_HELPER_STOP_FETCHING, stopFetching), (0, _defineProperty2["default"])(_createReducer, Types.GET_RESOURCES_REQUEST, function (state) {
  return state;
}), (0, _defineProperty2["default"])(_createReducer, Types.GET_RESOURCES_REQUEST_SUCCESS, getResourcesRequestSuccessR), _createReducer));
exports.reducer = reducer;