"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.loginFailure = exports.loginSuccess = exports.login = exports.rememberChange = exports.passwordChange = exports.emailChange = exports.errorChange = exports.getLocaleSuccess = exports.getLocale = exports.isLoggedResponse = exports.isLogged = exports.INITIAL_STATE = exports["default"] = exports.LoginTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

var _PreProcessLocale = _interopRequireDefault(require("../__Core/PreProcessLocale"));

var _createReducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  onLoginIsLoggedIn: null,
  onLoginIsLoggedInResponse: ['logged'],
  onGetLocale: null,
  onGetLocaleSuccess: ['translations'],
  onLoginErrorChange: ['name', 'error'],
  onLoginEmailChange: ['email'],
  onLoginPasswordChange: ['password'],
  onLoginRememberChange: null,
  onLogin: ['data'],
  onLoginSuccess: null,
  onLoginFailure: ['errorCode', 'errorObject']
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var LoginTypes = Types;
exports.LoginTypes = LoginTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  // eslint-disable-line
  email: '',
  errorCode: '',
  fetching: false,
  fetchingLocale: false,
  logged: false,
  password: '',
  remember: false,
  selectedGroupId: 1,
  translations: {},
  validationErrors: {}
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var isLogged = function isLogged(state) {
  return state.merge({
    fetching: true
  });
};

exports.isLogged = isLogged;

var isLoggedResponse = function isLoggedResponse(state, _ref) {
  var logged = _ref.logged;
  return state.merge({
    logged: logged,
    fetching: false
  });
};

exports.isLoggedResponse = isLoggedResponse;

var getLocale = function getLocale(state) {
  return state.merge({
    fetchingLocale: true
  });
};

exports.getLocale = getLocale;

var getLocaleSuccess = function getLocaleSuccess(state, _ref2) {
  var translations = _ref2.translations;
  return state.merge({
    translations: (0, _PreProcessLocale["default"])(translations),
    fetchingLocale: false
  });
};

exports.getLocaleSuccess = getLocaleSuccess;

var errorChange = function errorChange(state, _ref3) {
  var name = _ref3.name,
      error = _ref3.error;

  var errors = _objectSpread({}, state.validationErrors);

  errors[name] = error;
  return state.merge({
    validationErrors: errors
  });
};

exports.errorChange = errorChange;

var emailChange = function emailChange(state, _ref4) {
  var email = _ref4.email;
  return state.merge({
    email: email
  });
};

exports.emailChange = emailChange;

var passwordChange = function passwordChange(state, _ref5) {
  var password = _ref5.password;
  return state.merge({
    password: password
  });
};

exports.passwordChange = passwordChange;

var rememberChange = function rememberChange(state) {
  return state.merge({
    remember: !state.remember
  });
};

exports.rememberChange = rememberChange;

var login = function login(state) {
  return state.merge({
    errorCode: '',
    fetching: true
  });
};

exports.login = login;

var loginSuccess = function loginSuccess(state) {
  return state.merge({
    fetching: false,
    logged: true
  });
};

exports.loginSuccess = loginSuccess;

var loginFailure = function loginFailure(state, _ref6) {
  var errorCode = _ref6.errorCode,
      errorObject = _ref6.errorObject;
  return state.merge({
    errorCode: errorCode,
    fetching: false,
    validationErrors: _objectSpread({}, state.validationErrors, {}, errorObject)
  });
};
/* ------------- Hookup Reducers To Types ------------- */


exports.loginFailure = loginFailure;
var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (_createReducer = {}, (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_IS_LOGGED_IN, isLogged), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_IS_LOGGED_IN_RESPONSE, isLoggedResponse), (0, _defineProperty2["default"])(_createReducer, Types.ON_GET_LOCALE, getLocale), (0, _defineProperty2["default"])(_createReducer, Types.ON_GET_LOCALE_SUCCESS, getLocaleSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_ERROR_CHANGE, errorChange), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_EMAIL_CHANGE, emailChange), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_PASSWORD_CHANGE, passwordChange), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_REMEMBER_CHANGE, rememberChange), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN, login), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_SUCCESS, loginSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOGIN_FAILURE, loginFailure), _createReducer));
exports.reducer = reducer;