"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMessageFromValidationError = exports.createValidationError = exports.formatString = exports.getValueForPath = exports.generateUniqueKey = exports.isNumeric = exports.validatePhone = exports.validateEmail = exports.validateCreditCard = exports.randomIntFromInterval = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * @description Returns random int number in given interval
 */
var randomIntFromInterval = function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
/**
 * @description Validate card number by Luhn algorithm
 */


exports.randomIntFromInterval = randomIntFromInterval;

var validateCreditCard = function validateCreditCard(value) {
  if (/[^0-9-\s]+/.test(value)) return false;
  var nCheck = 0;
  var nDigit = 0;
  var bEven = false;
  value = value.replace(/\D/g, '');

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);

    if (bEven) {
      var b = nDigit *= 2;
      if (b > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};
/**
 * @description Validate email address
 */


exports.validateCreditCard = validateCreditCard;

var validateEmail = function validateEmail(value) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
};
/**
 * @description Validate phone format
 */


exports.validateEmail = validateEmail;

var validatePhone = function validatePhone(value) {
  return /^([0|+]{1}[0-9]{1,5}){1}([0-9]{8,10})$/.test(value.replace(' ', ''));
};
/**
 * @description Validate if value is numeric
 */


exports.validatePhone = validatePhone;

var isNumeric = function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

exports.isNumeric = isNumeric;

var generateUniqueKey = function generateUniqueKey() {
  return Math.random().toString(36).substring(2, 15);
};

exports.generateUniqueKey = generateUniqueKey;

var getValueForPath = function getValueForPath(pathArray, item) {
  var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (typeof pathArray === 'string') {
    return getValueForPath(pathArray.split('.'), item, index);
  }

  if (Array.isArray(item) && item.length > 0 && !item[pathArray[index]]) {
    return getValueForPath(pathArray, item[0], index);
  }

  if (!item || !pathArray || !item[pathArray[index]]) {
    return '';
  }

  if (pathArray.length - 1 === index) {
    return item[pathArray[index]];
  }

  return getValueForPath(pathArray, item[pathArray[index]], index + 1);
};
/**
 * Formats string base on provided pattern
 * @param {string} pattern - '{id}. {name}'
 * @param {object} values - {id: 1, name: 'Mr. Ex'}
 */


exports.getValueForPath = getValueForPath;

var formatString = function formatString(pattern, values) {
  return pattern.split('{').reduce(function (acc, itemPattern) {
    if (itemPattern.indexOf('}') !== -1) {
      var split = itemPattern.split('}');
      acc += getValueForPath(split[0], values) + split[1];
    } else {
      acc += itemPattern;
    }

    return acc;
  }, '');
};
/**
 * Creates validation error result
 * @param {string} msg
 * @param {object} data
 */


exports.formatString = formatString;

var createValidationError = function createValidationError(msg) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    msg: msg,
    data: data
  };
};
/**
 * Get first validation error, can be with suffix
 * @param error
 * @returns object
 */


exports.createValidationError = createValidationError;

var getMessageFromValidationError = function getMessageFromValidationError(error) {
  var suffix = {
    msg: '',
    data: {}
  };
  var validationError = error;

  if (Array.isArray(error)) {
    validationError = error[0];

    if (error.length > 1) {
      suffix.msg = 'validationErrors.suffix';
      suffix.data = {
        context: error.length - 1
      };
    }
  }

  return validationError ? _objectSpread({}, validationError.result, {
    suffix: suffix
  }) : false;
};

exports.getMessageFromValidationError = getMessageFromValidationError;