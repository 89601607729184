"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.updateLocation = exports.INITIAL_STATE = exports["default"] = exports.SettingsTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

// external libs

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  locationChange: null
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var SettingsTypes = Types;
exports.SettingsTypes = SettingsTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = '/';
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var updateLocation = function updateLocation(state) {
  return state;
};
/* ------------- Hookup Reducers To Types ------------- */


exports.updateLocation = updateLocation;
var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (0, _defineProperty2["default"])({}, Types.LOCATION_CHANGE, updateLocation));
exports.reducer = reducer;