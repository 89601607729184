"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _ListRedux = _interopRequireWildcard(require("../Redux/ListRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

// external libs
// redux
var RemoveItemsEpic = function RemoveItemsEpic(action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_ListRedux.ListTypes.ON_LIST_DELETE_REQUEST_CONFIRMED), (0, _operators.switchMap)(function (action) {
    var request = null;

    if (action.items.some(function (item) {
      return typeof item.id !== 'undefined';
    })) {
      request = _Api["default"].removeItems(action.moduleName, action.items.map(function (item) {
        return item.id;
      }));
    } else {
      request = _Api["default"].removeItemsWithMultipleParams(action.moduleName, action.items);
    }

    return (0, _rxjs.from)(request).pipe((0, _operators.switchMap)(function () {
      return (0, _rxjs.from)([_ListRedux["default"].onListDeleteRequest(false, []), _ListRedux["default"].onChangeRefreshSig(true), _NotificationRedux["default"].addSuccessNotification('ITEMS_SUCCESSFULLY_DELETED_MESSAGE', 'ITEMS_SUCCESSFULLY_DELETED_TITLE', true)]);
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.of)(_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'));
    }));
  }));
};

var _default = RemoveItemsEpic;
exports["default"] = _default;