"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _UserRedux = _interopRequireWildcard(require("../Redux/UserRedux"));

var _NavigationRedux = _interopRequireDefault(require("../Redux/NavigationRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

var _ListRedux = _interopRequireDefault(require("../Redux/ListRedux"));

var _WidgetRedux = _interopRequireDefault(require("../Redux/WidgetRedux"));

// external libs
// redux
var ActualUserEpics = [function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_UserRedux.UserTypes.ON_GET_ACTUAL_USER_REQUEST), (0, _operators.switchMap)(function () {
    return (0, _rxjs.from)(_Api["default"].actualUser()).pipe((0, _operators.switchMap)(function (response) {
      if (response.status === 200) {
        return (0, _rxjs.from)([_ListRedux["default"].onLoadListSettings(), _UserRedux["default"].onLoadUser(response.data.user, response.data.presentations), _NavigationRedux["default"].onLoadNavigation(response.data.navigation), _WidgetRedux["default"].setWidgets(response.data.widgets)]);
      }

      throw response;
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.of)(_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'));
    }));
  }));
}, function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_UserRedux.UserTypes.ON_CHANGE_USER_GROUP_REQUEST), (0, _operators.switchMap)(function (action) {
    return (0, _rxjs.from)(_Api["default"].setActualGroup({
      groupId: action.selectedGroupId
    })).pipe((0, _operators.switchMap)(function (response) {
      if (response.status === 200) {
        return (0, _rxjs.from)([_UserRedux["default"].onChangeUserGroupSuccess(response.data.selectedGroupId), _UserRedux["default"].onGetActualUserRequest(), _NavigationRedux["default"].onRequestRedirectTo('/dashboard')]);
      }

      throw response;
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.of)(_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'));
    }));
  }));
}];
var _default = ActualUserEpics;
exports["default"] = _default;