"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.loadUser = exports.onGetActualUserRequestR = exports.INITIAL_STATE = exports["default"] = exports.UserTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

var _createReducer;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  onGetActualUserRequest: null,
  onLoadUser: ['user', 'presentations'],
  onChangePresentationId: ['presentationId'],
  onChangeUserGroupRequest: ['selectedGroupId'],
  onChangeUserGroupSuccess: ['selectedGroupId']
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var UserTypes = Types;
exports.UserTypes = UserTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  userLoaded: false,
  email: '',
  image: '',
  username: '',
  name: '',
  surname: '',
  phone: '',
  role: 0,
  userGroups: [{
    key: 0,
    label: 'None'
  }],
  presentationIds: ['en', 'cs', 'pl'],
  presentationId: 'en',
  selectedGroup: {
    key: 0,
    label: 'None'
  }
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var onGetActualUserRequestR = function onGetActualUserRequestR(state) {
  return state.set('userLoaded', false);
};

exports.onGetActualUserRequestR = onGetActualUserRequestR;

var loadUser = function loadUser(state, _ref) {
  var user = _ref.user,
      presentations = _ref.presentations;
  return state.merge(_objectSpread({}, user, {
    userLoaded: true,
    presentations: presentations.map(function (presentation) {
      return presentation.label;
    }),
    selectedGroup: user.userGroups.filter(function (group) {
      return group.key === user.selectedGroupId;
    })[0]
  }));
};

exports.loadUser = loadUser;

var onChangePresentationIdR = function onChangePresentationIdR(state, _ref2) {
  var presentationId = _ref2.presentationId;
  return state.set('presentationId', presentationId);
};

var changeUserGroupSuccessR = function changeUserGroupSuccessR(state, _ref3) {
  var selectedGroupId = _ref3.selectedGroupId;
  return state.set('selectedGroup', state.userGroups.filter(function (group) {
    return group.key === selectedGroupId;
  })[0]);
};
/* ------------- Hookup Reducers To Types ------------- */


var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (_createReducer = {}, (0, _defineProperty2["default"])(_createReducer, Types.ON_CHANGE_PRESENTATION_ID, onChangePresentationIdR), (0, _defineProperty2["default"])(_createReducer, Types.ON_CHANGE_USER_GROUP_REQUEST, function (state) {
  return state;
}), (0, _defineProperty2["default"])(_createReducer, Types.ON_CHANGE_USER_GROUP_SUCCESS, changeUserGroupSuccessR), (0, _defineProperty2["default"])(_createReducer, Types.ON_GET_ACTUAL_USER_REQUEST, onGetActualUserRequestR), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOAD_USER, loadUser), _createReducer));
exports.reducer = reducer;