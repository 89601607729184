"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _ListRedux = _interopRequireWildcard(require("../Redux/ListRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

// external libs
// redux
var ListSettingsEpic = [function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_ListRedux.ListTypes.ON_LOAD_LIST_SETTINGS), (0, _operators.switchMap)(function () {
    return (0, _rxjs.from)(_Api["default"].listSettings()).pipe((0, _operators.switchMap)(function (response) {
      return (0, _rxjs.of)(_ListRedux["default"].onLoadListSettingsSuccess(response.data));
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.of)(_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'));
    }));
  }));
}, function (action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_ListRedux.ListTypes.ON_LOAD_LIST_WIDGET_SETTINGS), (0, _operators.switchMap)(function (action) {
    return (0, _rxjs.from)(_Api["default"].listWidgetSettings(action.module, action.widgetName)).pipe((0, _operators.switchMap)(function (response) {
      return (0, _rxjs.of)(_ListRedux["default"].onLoadListWidgetSettingsSuccess(action.module, action.widgetName, response.data));
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.of)(_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _ListRedux["default"].onWidgetStopFetching());
    }));
  }));
}];
var _default = ListSettingsEpic;
exports["default"] = _default;