"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _EditRedux = _interopRequireWildcard(require("../Redux/EditRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

// external libs
// redux
var GetConfigEpic = function GetConfigEpic(action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_EditRedux.EditTypes.ON_EDIT_LOAD_FORM_CONFIG), (0, _operators.switchMap)(function (action) {
    var url = action.data.type === 'ADD' ? _Api["default"].getAddConfig(action.data.module) : action.data.type === 'VIEW' ? _Api["default"].getViewConfig(action.data.module) : _Api["default"].getEditConfig(action.data.module);
    return (0, _rxjs.from)(url).pipe((0, _operators.switchMap)(function (response) {
      if (response.status === 200) {
        var actions = [_EditRedux["default"].onEditLoadFormConfigSuccess(response.data.module, response.data.config)];

        if (action.data.type !== 'ADD' && action.data.id) {
          actions.push(_EditRedux["default"].onEditLoadFormData(action.data));
        }

        return (0, _rxjs.from)(actions);
      }

      throw response;
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _EditRedux["default"].onEditSetFetching(false)]);
    }));
  }));
};

var _default = GetConfigEpic;
exports["default"] = _default;