"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _Switch = _interopRequireDefault(require("@material-ui/core/Switch"));

var Toggle = function Toggle(props) {
  var handleOnChange = function handleOnChange(event, isChecked) {
    props.onChange(isChecked);
  };

  return _react["default"].createElement(_Switch["default"], {
    checked: props.toggled,
    disabled: props.disabled,
    disableRipple: props.disabled,
    onChange: handleOnChange
  });
};

var _default = Toggle;
exports["default"] = _default;