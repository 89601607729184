"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var BaseObject = function BaseObject() {
  var _this = this;

  var attr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  (0, _classCallCheck2["default"])(this, BaseObject);
  (0, _defineProperty2["default"])(this, "getName", function () {
    return _this.name;
  });
  this.name = '';
  Object.keys(attr).map(function (key) {
    return _this[key] = attr[key];
  });
};

var _default = BaseObject;
exports["default"] = _default;