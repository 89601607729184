import React from 'react'
import ReactDOM from 'react-dom'

import createStore from '@bhe/react-core/lib/store/createStore'
import AppContainer from '@bhe/react-core/lib/Containers/AppContainer'

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = () => {

  ReactDOM.render(
    <AppContainer store={store} />,
    MOUNT_NODE
  )
}

// ========================================================
// Go!
// ========================================================
render()
