"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _redux = require("redux");

var _reduxThunk = _interopRequireDefault(require("redux-thunk"));

var _reduxObservable = require("redux-observable");

var _reducers = _interopRequireDefault(require("./reducers"));

var _Epics = _interopRequireDefault(require("../Epics"));

var __DEV__ = true;

var _default = function _default() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var epicMiddleware = (0, _reduxObservable.createEpicMiddleware)(); // ======================================================
  // Middleware Configuration
  // ======================================================

  var middleware = [_reduxThunk["default"]]; // ======================================================
  // Store Enhancers
  // ======================================================

  var enhancers = [];

  if (__DEV__) {
    var _window = window,
        devToolsExtension = _window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  middleware.push(epicMiddleware); // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================

  var store = (0, _redux.createStore)((0, _reducers["default"])(), initialState, _redux.compose.apply(void 0, [_redux.applyMiddleware.apply(void 0, middleware)].concat(enhancers)));
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', function () {
      var reducers = require('./reducers')["default"];

      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  epicMiddleware.run(_Epics["default"]);
  return store;
};

exports["default"] = _default;