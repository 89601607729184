"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _i18nReact = _interopRequireDefault(require("i18n-react"));

var YesNoComponent = function YesNoComponent(props) {
  return _react["default"].createElement("span", null, props.value ? _i18nReact["default"].translate('general.yes') : _i18nReact["default"].translate('general.no'));
};

var _default = YesNoComponent;
exports["default"] = _default;