"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _ValidationRules = _interopRequireDefault(require("./ValidationRules"));

var _CustomValidationRules = _interopRequireDefault(require("./CustomValidationRules"));

var BaseFormItemValidator = function BaseFormItemValidator() {
  var _this = this;

  (0, _classCallCheck2["default"])(this, BaseFormItemValidator);
  (0, _defineProperty2["default"])(this, "setRules", function (rules) {
    _this.rules = rules;
    return _this;
  });
  (0, _defineProperty2["default"])(this, "getErrors", function () {
    return _this.errors;
  });
  (0, _defineProperty2["default"])(this, "validate", function (value) {
    var validationKeys = Object.keys(_this.rules);
    var isAndCanBeEmpty = false;

    if (_this.rules.canBeEmpty) {
      isAndCanBeEmpty = _ValidationRules["default"].canBeEmpty(value);
    }

    _this.errors = validationKeys.reduce(function (errors, validationRule) {
      var validationConfigValue = _this.rules[validationRule];

      if (!isAndCanBeEmpty && validationRule !== 'canBeEmpty') {
        var result = false;

        if (_CustomValidationRules["default"][validationRule]) {
          result = _CustomValidationRules["default"][validationRule](value, validationConfigValue, _this.data);
        } else if (_ValidationRules["default"][validationRule]) {
          result = _ValidationRules["default"][validationRule](value, validationConfigValue, _this.data);
        } else {
          result = 'Not a valid rule!';
        }

        if (result !== true) {
          errors.push({
            rule: validationRule,
            result: result
          });
          _this.allDataValid = false;
        }
      }

      return errors;
    }, []);
    _this.isValid = _this.errors.length === 0;
  });
  this.rules = {};
  this.errors = [];
  this.isValid = false;
};

exports["default"] = BaseFormItemValidator;