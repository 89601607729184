"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _redux = require("redux");

var makeRootReducer = function makeRootReducer() {
  return (0, _redux.combineReducers)({
    drawer: require('../Redux/DrawerRedux').reducer,
    location: require('../Redux/LocationRedux').reducer,
    login: require('../Redux/LoginRedux').reducer,
    navigation: require('../Redux/NavigationRedux').reducer,
    user: require('../Redux/UserRedux').reducer,
    list: require('../Redux/ListRedux').reducer,
    edit: require('../Redux/EditRedux').reducer,
    helpers: require('../Redux/HelperRedux').reducer,
    notification: require('../Redux/NotificationRedux').reducer,
    widget: require('../Redux/WidgetRedux').reducer
  });
};

var _default = makeRootReducer;
exports["default"] = _default;