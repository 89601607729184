"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Utilities = require("../../src/Lib/Utilities");

/**
 * Custom validation rules,
 * functions with arguments (value, valueFromConfig, allData) => { }
 * returns true when valid, createValidationError when not valid
 */
var _default = {};
exports["default"] = _default;