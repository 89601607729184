"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _axios = _interopRequireDefault(require("axios"));

// a library to wrap and simplify api calls
var serverUrl = FRONT_URL;
var frontUrlElement = document.getElementById('front-url');

if (frontUrlElement && frontUrlElement.value) {
  serverUrl = frontUrlElement.value;
} // our "constructor"


var api = _axios["default"].create({
  baseURL: "".concat(serverUrl, "/api/v1"),
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
});

var setHeader = function setHeader(name, value) {
  if (value) {
    _axios["default"].defaults.headers.common[name] = value;
  } else {
    delete _axios["default"].defaults.headers.common[name];
  }
};

var endpoints = {
  // post
  login: function login(data) {
    return api.post('login/', data);
  },
  postSingleImageUpload: function postSingleImageUpload(data) {
    return api.post('upload/image', data);
  },
  postSingleFileUpload: function postSingleFileUpload(data) {
    return api.post('upload/file', data);
  },
  addData: function addData(module, data) {
    return api.post("add/".concat(module), data);
  },
  addValidateData: function addValidateData(module, data) {
    return api.post("add/validate/".concat(module), data);
  },
  editValidateData: function editValidateData(module, data) {
    return api.post("edit/validate/".concat(module), data);
  },
  // put
  editPayment: function editPayment(data) {
    return api.put('payment/', data);
  },
  editData: function editData(module, data) {
    return api.put("edit/".concat(module), data);
  },
  updateData: function updateData(module, data) {
    return api.put("edit/".concat(module), data);
  },
  setActualGroup: function setActualGroup(data) {
    return api.put('user/group', data);
  },
  // get
  actualUser: function actualUser() {
    return api.get('user/actual');
  },
  isLoggedIn: function isLoggedIn() {
    return api.get('login/logged-in');
  },
  locale: function locale() {
    return api.get('locale/');
  },
  listSettings: function listSettings() {
    return api.get('list/settings');
  },
  listWidgetSettings: function listWidgetSettings(module, widgetName) {
    return api.get('list/widget-settings', {
      params: {
        module: module,
        widgetName: widgetName
      }
    });
  },
  listData: function listData(module, page, limit, orderColumn, orderDirection, filter) {
    return api.get("list/".concat(module, "/").concat(page, "/").concat(limit, "/").concat(orderColumn, "/").concat(orderDirection), {
      params: {
        filter: filter
      }
    });
  },
  listWidgetData: function listWidgetData(module, widgetName, page, limit, orderColumn, orderDirection, filter, parentId, parentModule) {
    return api.get("list/".concat(module, "/").concat(page, "/").concat(limit, "/").concat(orderColumn, "/").concat(orderDirection), {
      params: {
        filter: filter,
        widgetName: widgetName,
        parentId: parentId,
        parentModule: parentModule
      }
    });
  },
  getAddConfig: function getAddConfig(module) {
    return api.get("add/config/".concat(module));
  },
  getViewConfig: function getViewConfig(module) {
    return api.get("view/config/".concat(module));
  },
  getEditConfig: function getEditConfig(module) {
    return api.get("edit/config/".concat(module));
  },
  getAddData: function getAddData(module, id) {
    return api.get("add/".concat(module, "/").concat(id));
  },
  getViewData: function getViewData(module, id) {
    return api.get("view/".concat(module, "/").concat(id));
  },
  getEditData: function getEditData(module, id) {
    return api.get("edit/".concat(module, "/").concat(id));
  },
  getResources: function getResources(resourceName) {
    return api.get("resources/".concat(resourceName));
  },
  // delete
  removeItems: function removeItems(module, itemIds) {
    return api["delete"]("delete/".concat(module), {
      params: {
        itemIds: itemIds
      }
    });
  },
  removeItemsWithMultipleParams: function removeItemsWithMultipleParams(module, items) {
    return api["delete"]("delete/".concat(module), {
      data: items
    });
  },
  // helpers
  serverUrl: serverUrl,
  setHeader: setHeader
};
var _default = endpoints;
exports["default"] = _default;