/**
 * @description Returns random int number in given interval
 */
export const randomIntFromInterval = (min, max) =>
  Math.floor((Math.random() * ((max - min) + 1)) + min)

/**
 * @description Validate card number by Luhn algorithm
 */
export const validateCreditCard = (value) => {
  if (/[^0-9-\s]+/.test(value)) return false

  let nCheck = 0
  let nDigit = 0
  let bEven = false
  value = value.replace(/\D/g, '')

  for (let n = value.length - 1; n >= 0; n--) {
    const cDigit = value.charAt(n)
    nDigit = parseInt(cDigit, 10)

    if (bEven) {
      const b = nDigit *= 2
      if (b > 9) nDigit -= 9
    }

    nCheck += nDigit
    bEven = !bEven
  }

  return (nCheck % 10) === 0
}

/**
 * @description Validate email address
 */
export const validateEmail = value =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)

/**
 * @description Validate phone format
 */
export const validatePhone = value =>
  /^([0|+]{1}[0-9]{1,5}){1}([0-9]{8,10})$/.test(value.replace(' ', ''))

/**
 * @description Validate if value is numeric
 */
export const isNumeric = n => (!isNaN(parseFloat(n)) && isFinite(n))

export const generateUniqueKey = () => Math.random().toString(36).substring(2, 15)

export const getValueForPath = (pathArray: string | Array<string | number>, item: Object, index: number = 0): any => {
  if (typeof pathArray === 'string') {
    return getValueForPath(pathArray.split('.'), item, index)
  }

  if (Array.isArray(item) && item.length > 0 && !item[pathArray[index]]) {
    return getValueForPath(pathArray, item[0], index)
  }

  if (!item || !pathArray || !item[pathArray[index]]) {
    return ''
  }

  if (pathArray.length - 1 === index) {
    return item[pathArray[index]]
  }

  return getValueForPath(pathArray, item[pathArray[index]], index + 1)
}

/**
 * Formats string base on provided pattern
 * @param {string} pattern - '{id}. {name}'
 * @param {object} values - {id: 1, name: 'Mr. Ex'}
 */
export const formatString = (pattern, values) =>
  pattern.split('{').reduce((acc, itemPattern) => {
    if (itemPattern.indexOf('}') !== -1) {
      const split = itemPattern.split('}')
      acc += getValueForPath(split[0], values) + split[1]
    } else {
      acc += itemPattern
    }
    return acc
  }, '')

/**
 * Creates validation error result
 * @param {string} msg
 * @param {object} data
 */
export const createValidationError = (msg, data = {}) => ({
  msg,
  data
})

/**
 * Get first validation error, can be with suffix
 * @param error
 * @returns object
 */
export const getMessageFromValidationError = (error) => {
  const suffix = { msg: '', data: {} }
  let validationError = error
  if (Array.isArray(error)) {
    validationError = error[0]
    if (error.length > 1) {
      suffix.msg = 'validationErrors.suffix'
      suffix.data = { context: error.length - 1 }
    }
  }
  return validationError ? { ...validationError.result, suffix } : false
}
