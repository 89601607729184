"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActionsArray = exports.getAllRights = exports.hasRightsForAction = exports.getNavigationItem = exports.USER_RIGHTS = exports.reducer = exports.loadNavigation = exports.redirectSuccess = exports.requestRedirectTo = exports.INITIAL_STATE = exports["default"] = exports.NavigationTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxsauce = require("reduxsauce");

var _seamlessImmutable = _interopRequireDefault(require("seamless-immutable"));

var _i18nReact = _interopRequireDefault(require("i18n-react"));

var _createReducer;

/* ------------- Types and Action Creators ------------- */
var _createActions = (0, _reduxsauce.createActions)({
  onRequestRedirectTo: ['redirectTo'],
  onRedirectSuccess: null,
  onLoadNavigation: ['navigation']
}),
    Types = _createActions.Types,
    Creators = _createActions.Creators;

var NavigationTypes = Types;
exports.NavigationTypes = NavigationTypes;
var _default = Creators;
/* ------------- Initial State ------------- */

exports["default"] = _default;
var INITIAL_STATE = (0, _seamlessImmutable["default"])({
  // eslint-disable-line
  redirectTo: '',
  navigation: []
});
/* ------------- Reducers ------------- */

exports.INITIAL_STATE = INITIAL_STATE;

var requestRedirectTo = function requestRedirectTo(state, _ref) {
  var redirectTo = _ref.redirectTo;
  return state.merge({
    redirectTo: redirectTo
  });
};

exports.requestRedirectTo = requestRedirectTo;

var redirectSuccess = function redirectSuccess(state) {
  return state.merge({
    redirectTo: INITIAL_STATE.redirectTo
  });
};

exports.redirectSuccess = redirectSuccess;

var loadNavigation = function loadNavigation(state, _ref2) {
  var navigation = _ref2.navigation;
  return state.merge({
    navigation: navigation
  });
};
/* ------------- Hookup Reducers To Types ------------- */


exports.loadNavigation = loadNavigation;
var reducer = (0, _reduxsauce.createReducer)(INITIAL_STATE, (_createReducer = {}, (0, _defineProperty2["default"])(_createReducer, Types.ON_REQUEST_REDIRECT_TO, requestRedirectTo), (0, _defineProperty2["default"])(_createReducer, Types.ON_REDIRECT_SUCCESS, redirectSuccess), (0, _defineProperty2["default"])(_createReducer, Types.ON_LOAD_NAVIGATION, loadNavigation), _createReducer));
/* ------------- Helpers ------------- */

exports.reducer = reducer;
var USER_RIGHTS = {
  LIST: 1,
  VIEW: 2,
  ADD: 4,
  EDIT: 8,
  DELETE: 16
};
exports.USER_RIGHTS = USER_RIGHTS;

var getNavigationItem = function getNavigationItem(navigation, moduleName) {
  return navigation.reduce(function (acc, item) {
    if (item.isParent) {
      var fromChildrenNavigation = getNavigationItem(item.items, moduleName);
      acc = fromChildrenNavigation && fromChildrenNavigation.actions ? fromChildrenNavigation : acc;
    } else if (item.name === moduleName) {
      acc = item;
    }

    return acc;
  }, {});
};

exports.getNavigationItem = getNavigationItem;

var hasRightsForAction = function hasRightsForAction(navigation, moduleName, action) {
  var navigationItem = getNavigationItem(navigation, moduleName);
  return navigationItem && navigationItem.actions & action;
};

exports.hasRightsForAction = hasRightsForAction;

var getAllRights = function getAllRights(navigation, moduleName) {
  return Object.keys(USER_RIGHTS).reduce(function (acc, action) {
    acc[USER_RIGHTS[action]] = hasRightsForAction(navigation, moduleName, USER_RIGHTS[action]);
    return acc;
  }, {});
};

exports.getAllRights = getAllRights;

var getActionsArray = function getActionsArray(rights, moduleActions) {
  var actions = [];

  if (rights[USER_RIGHTS.VIEW] && moduleActions.indexOf('view') !== -1) {
    actions.push({
      key: 0,
      label: _i18nReact["default"].translate('general.view'),
      action: USER_RIGHTS.VIEW
    });
  }

  if (rights[USER_RIGHTS.EDIT] && moduleActions.indexOf('edit') !== -1) {
    actions.push({
      key: 1,
      label: _i18nReact["default"].translate('general.edit'),
      action: USER_RIGHTS.EDIT
    });
  }

  if (rights[USER_RIGHTS.DELETE] && moduleActions.indexOf('delete') !== -1) {
    actions.push({
      key: 2,
      label: _i18nReact["default"].translate('general.delete'),
      action: USER_RIGHTS.DELETE
    });
  }

  return actions;
};

exports.getActionsArray = getActionsArray;