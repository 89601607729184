"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _EditRedux = _interopRequireWildcard(require("../Redux/EditRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

// external libs
// redux
var GetItemData = function GetItemData(action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_EditRedux.EditTypes.ON_EDIT_LOAD_FORM_DATA), (0, _operators.switchMap)(function (action) {
    var apiFunction = action.data.type === 'ADD' ? _Api["default"].getAddData : action.data.type === 'VIEW' ? _Api["default"].getViewData : _Api["default"].getEditData;
    return (0, _rxjs.from)(apiFunction(action.data.module, action.data.id)).pipe((0, _operators.switchMap)(function (response) {
      if (response.status === 200) {
        return (0, _rxjs.of)(_EditRedux["default"].onEditLoadFormDataSuccess(response.data.module, response.data.data));
      }

      throw response;
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _EditRedux["default"].onEditSetFetching(false)]);
    }));
  }));
};

var _default = GetItemData;
exports["default"] = _default;