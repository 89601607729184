"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reduxObservable = require("redux-observable");

var _rxjs = require("rxjs");

var _operators = require("rxjs/operators");

var _Api = _interopRequireDefault(require("../Services/Api"));

var _HelperRedux = _interopRequireWildcard(require("../Redux/HelperRedux"));

var _NotificationRedux = _interopRequireDefault(require("../Redux/NotificationRedux"));

var _EditRedux = _interopRequireDefault(require("../Redux/EditRedux"));

// external libs
// redux
var PostSingleFileUploadEpic = function PostSingleFileUploadEpic(action$) {
  return action$.pipe((0, _reduxObservable.ofType)(_HelperRedux.HelperTypes.ON_HELPER_SINGLE_FILE_UPLOAD), (0, _operators.switchMap)(function (action) {
    var formData = new FormData();
    formData.append(action.fileType ? action.fileType : 'image', action.data);
    return (0, _rxjs.from)(action.fileType === 'file' ? _Api["default"].postSingleFileUpload(formData) : _Api["default"].postSingleImageUpload(formData)).pipe((0, _operators.switchMap)(function (response) {
      return (0, _rxjs.of)(_HelperRedux["default"].onHelperSingleFileUploadSuccess(action.name, response.data));
    }), (0, _operators.catchError)(function (error) {
      return (0, _rxjs.from)([_NotificationRedux["default"].addErrorNotification(error.toString(), 'SOMETHING_WRONG'), _EditRedux["default"].onEditSetFetching(false)]);
    }));
  }));
};

var _default = PostSingleFileUploadEpic;
exports["default"] = _default;