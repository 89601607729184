"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _reduxObservable = require("redux-observable");

var _ActualUserEpics = _interopRequireDefault(require("./ActualUserEpics"));

var _GetLocaleEpic = _interopRequireDefault(require("./GetLocaleEpic"));

var _IsLoggedInEpic = _interopRequireDefault(require("./IsLoggedInEpic"));

var _LoginEpic = _interopRequireDefault(require("./LoginEpic"));

var _ListSettingsEpic = _interopRequireDefault(require("./ListSettingsEpic"));

var _ListDataEpic = _interopRequireDefault(require("./ListDataEpic"));

var _RemoveItemsEpic = _interopRequireDefault(require("./RemoveItemsEpic"));

var _GetConfigEpic = _interopRequireDefault(require("./GetConfigEpic"));

var _GetItemData = _interopRequireDefault(require("./GetItemData"));

var _PostSingleImageUploadEpic = _interopRequireDefault(require("./PostSingleImageUploadEpic"));

var _PostSaveDataEpic = _interopRequireDefault(require("./PostSaveDataEpic"));

// external libs
// epics
var epics = [].concat((0, _toConsumableArray2["default"])(_ActualUserEpics["default"]), [_GetLocaleEpic["default"], _IsLoggedInEpic["default"], _LoginEpic["default"]], (0, _toConsumableArray2["default"])(_ListSettingsEpic["default"]), (0, _toConsumableArray2["default"])(_ListDataEpic["default"]), [_RemoveItemsEpic["default"], _GetConfigEpic["default"], _GetItemData["default"], _PostSingleImageUploadEpic["default"]], (0, _toConsumableArray2["default"])(_PostSaveDataEpic["default"]));

var rootEpic = _reduxObservable.combineEpics.apply(void 0, (0, _toConsumableArray2["default"])(epics));

var _default = rootEpic;
exports["default"] = _default;