"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

var _reactCustomScrollbars = require("react-custom-scrollbars");

var _Drawer = _interopRequireDefault(require("@material-ui/core/Drawer"));

var _classnames = _interopRequireDefault(require("classnames"));

var _NavbarComponent = _interopRequireDefault(require("./NavbarComponent"));

var _Popover = _interopRequireDefault(require("./_ui/Popover"));

// component
var drawerLogoWrapper = (0, _classnames["default"])({
  logoWrapper: true,
  logoWrapperFull: true
});

var Header = function Header(props) {
  return _react["default"].createElement("div", null, _react["default"].createElement("div", {
    className: "topBar"
  }, _react["default"].createElement("div", {
    className: "columnLeft"
  }, _react["default"].createElement("div", {
    className: "logoWrapper"
  }), _react["default"].createElement("div", {
    className: "showSidebar",
    onClick: props.handleToggle
  }, _react["default"].createElement("i", {
    className: "fas fa-bars icon"
  }))), _react["default"].createElement("div", {
    className: "content"
  }), _react["default"].createElement("div", {
    className: "columnRight"
  }, _react["default"].createElement("div", {
    className: "actions"
  }, _react["default"].createElement("div", {
    className: "action"
  }, _react["default"].createElement(_Popover["default"], {
    color: "primary",
    label: props.presentationId,
    menuItems: props.presentationIds,
    onClick: props.onChangePresentationId
  })), _react["default"].createElement("div", {
    className: "action"
  }, _react["default"].createElement(_Popover["default"], {
    color: "primary",
    label: props.selectedGroup.label,
    menuItems: props.userGroups,
    onClick: props.onChangeUserGroup
  })), _react["default"].createElement(_reactRouterDom.Link, {
    className: "action rotate",
    to: "/settings"
  }, _react["default"].createElement("i", {
    className: "fas fa-cog icon"
  }))))), _react["default"].createElement(_Drawer["default"], {
    onClose: props.handleToggle,
    open: props.open,
    width: 250
  }, _react["default"].createElement(_reactCustomScrollbars.Scrollbars, {
    autoHide: true,
    autoHideDuration: 200,
    autoHideTimeout: 1000,
    style: {
      width: 250
    }
  }, _react["default"].createElement("div", {
    className: "drawer-body"
  }, _react["default"].createElement("div", {
    className: "topBar"
  }, _react["default"].createElement("div", {
    className: "columnLeft"
  }, _react["default"].createElement("div", {
    className: drawerLogoWrapper
  }))), _react["default"].createElement(_NavbarComponent["default"], {
    handleToggle: props.handleToggle,
    navigation: props.navigation
  })))));
};

var _default = Header;
exports["default"] = _default;